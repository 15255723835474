import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";


import Moment from 'react-moment';
import moment from 'moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
const Config = require('config');


class TransactionInfo extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                  transactions:[],
                from: undefined,
                to: undefined,
                alert: null,
                paid_amount:0,
                total:0,
                  refid:'',
                 date:'',
                  status:'',
                  currency:'USD',
                invid:'',
                assets:[]
                  
                }
            
            }
            getLink(asset,txhash){


              var assetupper=asset.toUpperCase();
              
              var link=''
                this.state.assets.filter(asset => asset.asset_id == assetupper ).map(filteredAssets => (
              
              link= filteredAssets.explorer_url
              
                ))
              
                return link+txhash
              
              }
            cancel = (invid,ref_id) => {
              this.setState({   alert:(
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Are you sure?"
                  onConfirm={() => this.cancelconfirmed(invid,ref_id)}
                  onCancel={() => this.hidealert()}
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  confirmBtnText="Yes, Cancel it!"
                  cancelBtnText="No"
                  showCancel
                >
                  You will not be able to recover this imaginary file!
                </SweetAlert>
              ) });
            };
           hidealert = () => {
              this.setState({   alert:''})
           }
            
           cancelconfirmed=(invid,ref_id)=>{ 
    


            var user=JSON.parse(localStorage.getItem('user'));
            var token=user.token;
            var mid=user.data.merchant_id;
       
          
        
          
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json','access-token': token,'mid':mid},
             body: JSON.stringify({ref_id,invid})
          };
          
          
          return fetch(Config.API+'/api/pos/cancel',requestOptions)
            .then( this.handleResponse)
            .then( walletdata => this.setState({alert:'',loading:true},this.getpos(this.props.match.params.invid)));
        
            
        
        
            
              }

              componentDidMount(){
                var user=JSON.parse(localStorage.getItem('user'));
                this.setState({invid:this.props.match.params.invid,newinvoice:false,assets:user.assets}, this.getpos(this.props.match.params.invid),this.getpostransactions(this.props.match.params.invid))
                
                
                  }
 

                reloadtrans=()=>{
                  this.setState({invid:this.props.match.params.invid,newinvoice:false}, this.getpos(this.props.match.params.invid),this.getpostransactions(this.props.match.params.invid))
                }

getpostransactions(invid){


  var user=JSON.parse(localStorage.getItem('user'));

  const requestOptions = {
    method: 'get',
    headers: { 'Content-Type': 'application/json','access-token': user.token,'mid':user.data.merchant_id}
 
};



return fetch(Config.API+`/api/pos/transactions/`+invid,requestOptions)
  .then( this.handleResponse)
  .then( transactiondata => this.setState({transactions:transactiondata.data, loading: false}))};






  getpos(invid){

    var user=JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' ,'access-token': user.token,'mid':user.data.merchant_id },
    };

    fetch(Config.API+'/api/pos/'+invid,requestOptions)
    .then( this.handleResponse)
     .then( invoice=> this.setState({
  
      paid_amount:invoice.data.paid_amount,
      total:invoice.data.total_amount,
        refid:invoice.data.ref_id,
       date:invoice.data.date,
        status:invoice.data.payment_status,
        currency:invoice.data.currency,
      invid:this.props.match.params.invid, loading: false}));

     
  
    }





    back=()=>{ 
    
    window.location.href ="/#/transactions"
      }
    

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
           // localStorage.removeItem('user');
          //  window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}




  

  render() {
    const { from, to,currency,BtcTxUrl,BchTxUrl,EthTxUrl,LtcTxUrl,TronTxUrl,DashTxUrl,SolTxUrl,UsdtTxUrl,XrpTxUrl,DogeTxUrl,total,refid,status,paid_amount,alert,invid } = this.state;
    const modifiers = { start: from, end: to };
    const FORMAT = 'YYYY-MM-DD';
    var CurrencyOptions = [{ value: 'USD', label: 'USD'},{ value: 'CAD', label: 'CAD'},{ value: 'EUR', label: 'EUR'},{ value: 'GBP', label: 'GBP'}];
  
        

    
   
if (this.state.loading) return <Loader />
    return (
      <div className="main-content">
          {alert}
                        <div className="buttonHolder">    <Button
                      fill
                      marginright
                      bsStyle="danger"
                      type="button"
                      onClick={()=>{this.back()}}
                    >
                      Back
                    </Button>   <Button
                      fill
                      pullRight
                      marginleft
                      bsStyle="info"
                      type="button"
                      onClick={()=>{this.setState({loading:true},()=>{this.reloadtrans()})}}
                    >
                      <i class="fas fa-sync"></i>
                    </Button></div> 
      <Grid fluid>
 
        <Row>
 <Col md={12}> 
        
           
         <Col sm={8}><h5>Transaction ID: <strong className="block">{refid}</strong><br/>Status: <strong className="block">{status}</strong></h5> </Col>
       
         <Col sm={4}><table><tbody><tr><td>Billed amount:</td><td><strong>{currency==='USD' || currency==='CAD' ? '$':currency=='EUR'
? '€'
:'£'}{Number(total).toFixed(2)} {currency}</strong></td></tr><tr><td>Paid:</td><td><strong>{currency==='USD' || currency==='CAD' ? '$':currency=='EUR'
? '€'
:'£'}{Number(paid_amount).toFixed(2)} {currency}</strong></td></tr>
<tr><td>Amount due:</td><td><strong>{currency==='USD' || currency==='CAD' ? '$':currency=='EUR'
? '€'
:'£'}{Number(total - paid_amount).toFixed(2)} {currency}</strong></td></tr></tbody></table>
<div className="buttonHolder"> {status==='Partially Paid'?<Button
                        fill
                        marginright
                        bsStyle="warning"
                        type="button"
                      
                        onClick={() => window.location.hash = "#/new/"+this.state.invid}
                      >
                      Pay Remaing
                      </Button>:''} 
                      {status==='Outstanding'?<Button
                        fill
                        marginright
                        bsStyle="warning"
                        type="button"
                      
                        onClick={() => window.location.hash = "#/new/"+this.state.invid}
                      >
                     Make Payment
                      </Button>:''} 
                      
                      {status==='Outstanding'?<Button
                        fill
                        marginright
                        bsStyle="danger"
                        type="button"
                      
                       onClick={() => this.cancel(invid,refid)}
                      >
                      Cancel
                      </Button>:''} </div>
<br/></Col>     </Col> <Col sm={12}> 
         </Col>
                
           
         
                </Row>
        

                <Row><Col sm={12}>
                <div className="card">
                  <div className="header"><div className="title">Transaction Payments</div></div>
                  <div className="content">

              <table><thead><tr><td>Date</td><td>Status</td><td>Price ({this.state.currency})</td><td>Coin</td><td>Amount</td><td>Paid ({this.state.currency})</td><td>Tx Id</td></tr></thead><tbody>{ this.state.transactions.map((transaction, i) => <tr key={i}><td>{moment(transaction.date_time).format("MM/DD/YYYY H:m:s")}</td><td>{transaction.status}</td><td>{transaction.price} </td><td>{transaction.coin}</td><td>{transaction.payment_amount}</td><td>{Number(transaction.total_payment).toFixed(2)}</td><td><a href={this.getLink(transaction.coin,transaction.txid)} target="_blank">View transaction</a></td></tr>)}</tbody></table>

              
                
</div>


                </div>
                </Col></Row>

            
             
      </Grid>
    </div>


    );
  }
}

export default TransactionInfo;