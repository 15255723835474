import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import HeaderLinks from "./HeaderLinks.jsx";

// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

import dashboardRoutes from "routes/dashboard.jsx";
import logo from "logo.svg";
class Header extends Component {
  constructor(props) {
    super(props);
    this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
  }
  makeBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return  name;
  }
  // function that makes the sidebar from normal to mini and vice-versa
  handleMinimizeSidebar() {
    document.body.classList.toggle("sidebar-mini");
  }
  // function for responsive that hides/shows the sidebar
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }
  render() {
    return (
      <nav className="navbar navbar-default ">
      
      <div className="container-fluid">
      <div className="">
      <div className="navbar-brand">
           <div className='logo-img'><img src={logo} alt='Alt 5 pro'/></div><div className="section-name"></div>
           </div>
           </div>
       <div className="logout">
      <ul className="">
        <li className="">
          <a className="" href="/#/a/login-page">
          Sign Out <i class="fas fa-sign-out"></i></a></li></ul>
     </div>
      </div>
     
     
     
     </nav>
    );
  }
}

export default Header;
