import React, { Component } from "react";
import { Grid,  Row,   Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel } from "react-bootstrap";


import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import CurrencyInput from 'react-currency-input-field';
import "react-select/dist/react-select.css";

import NumberFormat from 'react-number-format';
import Paywidget from '../widgets/pay';

import ReactDOM from "react-dom";
const queryString = require('query-string');
const Config = require('config');
class NewTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total:0,
      currency:'USD',
      showCurrency:true,
      amount:0,
      amountError:null,
      refid:'POS_'+Date.now(),
      refError:null,
      stepCount:1,
      error: '',
      showcoins:true,
    coin:'',
    widget_id:'',
    address:'',
   price:0,
   invid:'',
mid:0,
paid_amount:0,
total_amount:0,
assets:[]

    }
    this.changeCurrency= this.changeCurrency.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }



  componentWillMount(){
   

    
        if(this.props.match.params.invid!==undefined){
      this.setState({stepCount:2},
        this.getpos(this.props.match.params.invid))}
       
        
      }

      

  componentDidMount(){
    
    var user=JSON.parse(localStorage.getItem('user'));
this.setState({currency:user.data.pos_currency,showCurrency:user.data.pos_show_currency,widget_id:user.data.widget_id,mid:user.data.merchant_id,assets:user.assets},()=>{

})

  }



  getpos(invid){

    var user=JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' ,'access-token': user.token,'mid':user.data.merchant_id },
    };

    fetch(Config.API+'/api/pos/'+invid,requestOptions)
    .then( this.handleResponse)
     .then( invoice=> this.setState({
  
      paid_amount:invoice.data.paid_amount,
      total_amount:invoice.data.total_amount,
        refid:invoice.data.ref_id,
       date:invoice.data.date,
        status:invoice.data.payment_status,
        currency:invoice.data.currency,
      invid:this.props.match.params.invid, loading: false,
      amount:Number(invoice.data.total_amount - invoice.data.paid_amount).toFixed(2)}));
     
  
    }


  changeCurrency=(event) =>
  this.setState({currency:event.target.value})

 step=(count)=>{

this.setState({stepCount:count},function(){
  window.scrollTo(0, 0)
}
)
  }

childBack=()=>{
  
  this.step(2)
}

payRemaining=(remainingamount)=>{

this.setState({amount:remainingamount},()=>{
  this.step(2)
})
  
}


  handleValidate(e){

    if(this.state.refid===''){
      this.setState({  refidError: (<small className="text-danger">Please enter a Transaction ID</small>) })

    }else{
      this.setState({  refidError: null })

    }

    if(this.state.amount<=0 || this.state.amount===undefined ){
      this.setState({  amountError: (<small className="text-danger">Amount has to be greater then 0</small>) })

    }else{
      this.setState({  amountError: null, amount:this.state.amount.toLocaleString(navigator.language, { minimumFractionDigits: 2 })})

    }
  
      


  }

  cancel=()=>{ 
 window.location.href ="/#/dashboard";

    


    
      }

  
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}

handleResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {

       // const error = (data && data.message);
        return 'error'
      }else{
      //  console.log(data.status)
        if(data.status=='error'){
       localStorage.removeItem('user');
        window.location.reload(true);
             return 'error'
        }


      }


      return data;
  });
}


handleSubmit(e) {
  e.preventDefault();

 if(  this.state.amountError  === null  && this.state.refidError  === null){




    var user=JSON.parse(localStorage.getItem('user'));
    var token=user.token;
    var ref_id=this.state.refid;
    var currency=this.state.currency;
    var total_amount=this.state.amount;
   

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id},
       body: JSON.stringify({ref_id,total_amount,currency})
    };
  
  
  return fetch(Config.API+'/api/pos/create',requestOptions)
    .then( this.handleResponse)
    .then( invoice => this.setState({invid:invoice.data.invoice_id},function(){
     
      this.step(2)
    }))
  
  
  
     
  
  
  
   




 }
}




loadcoin(props,coin,coinname,logo,invid,erc){ this.setState({stepCount:3},function(){


  const node = ReactDOM.findDOMNode(this);
  var element=''
// Get child nodes
if (node instanceof HTMLElement) {
   element = node.querySelector('.coinInfo');
}



  ReactDOM.render(
    
    <Paywidget amount={Number(this.state.amount).toFixed(2)} parentChildBack={this.childBack} parentChildpayRemaining={this.payRemaining} mid={this.state.mid} invid={invid} coinname={coinname} currency={this.state.currency} refid={this.state.refid} logo={logo} coin={coin} erc={erc}  widget_id={this.state.widget_id} {...props}/>, element);
})}








  render() {

    const {currency,changeCurrency,refid,amount,stepCount,showCurrency,refidError,amountError,widget_id,address,price,coin,invid} = this.state; 
    const  assetdata = []


    this.state.assets.filter(asset => asset.limit <= this.state.amount && asset.currency.includes(this.state.currency) ).map(filteredAssets => (


      assetdata.push({
        asset_id: (filteredAssets.asset_id).toLowerCase(),
        name: filteredAssets.name,
        logo:filteredAssets.icon_url,
        erc:filteredAssets.erc
      }
    )

    ))
    var CurrencyOptions = [{ value: 'USD', label: 'USD'},{ value: 'CAD', label: 'CAD'},{ value: 'EUR', label: 'EUR'},{ value: 'GBP', label: 'GBP'}];
    return (
      <div className="main-content">
       {/* step1 */}
       {stepCount===1
        ?<div className="step1">
        <Grid fluid>

          <Row>
 

          <Col sm={12} lg={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
            
                    <div>




                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={0}>
                       Transaction ID
                        </Col>
                        <Col sm={8}>
                        <FormControl
                            type="text"
                            name="refid"
                          value={refid}
                          onChange={this.handleChange} 
                          />
{refidError}
                        </Col>
                      </FormGroup>
                     <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                Currency
                        </Col>
                        <Col sm={2} md={2} lg={2}>
                          

                

             {showCurrency
              ?    <select  className="form-control"
        onChange={this.changeCurrency}
        value={currency}
      >
        <option value="USD">USD</option>
        <option value="CAD">CAD</option>
        <option value="EUR">EUR</option>
        <option value="GBP">GBP</option>
      </select>
  :this.state.currency}


                        </Col>
                      </FormGroup>
                    
                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={4} smOffset={0}>
                        Amount 
                        </Col>
                        <Col sm={8}>
                    
            
                        <CurrencyInput
                        className="form-control"
                       
  id="amount"
  name="amount"
  placeholder="Please enter amount"
 
  decimalsLimit={2}
 value={amount}
  allowNegativeValue={false}
 
  onValueChange={(value, name) => this.setState({[name]:value})}
/>
{amountError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                      
                      <Col componentClass={ControlLabel} sm={4} smOffset={0}>
                    
            </Col><Col sm={8}>
                      

                    <Button
                      fill
                      marginright
                      bsStyle="danger"
                      type="button"
                      onClick={this.cancel}
                    >
                      Cancel
                    </Button>
  <Button
                      fill
                      marginright
                      bsStyle="success" 
                      type="submit"
                      onClick={ this.handleValidate}
                    >
                      Charge
                    </Button>
                        </Col>
                      </FormGroup>
                    </div>
                 
              </Form>
            </Col>


          </Row>
        </Grid>

        </div>
:stepCount===2
     /* step2 */
      ? <div className="step2">
       <div className="buttonHolder">    <Button
                      fill
                      marginright
                      bsStyle="danger"
                      type="button"
                      onClick={this.cancel}
                    >
                      Cancel
                    </Button></div>
        <Grid fluid>
          <Row>
  <Col sm={12} md={6} lg={6}> <div className="mdFont tcenter vcenter">  Transaction {this.state.refid}
  <div className="largeFont">   {currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'}
  
  <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={this.state.amount} />  
  {' '+this.state.currency}</div> 
  
  
 
                    
                    </div> </Col> 
          <Col sm={12} md={6} lg={6}>  <div className="vcenter"><div className="mdFont tcenter">    <Col sm={12} className="marginBot10"> Select your Payment Currency</Col></div><div className="flexcol">
          {assetdata.map((asset) => (
 <Col lg={4} md={4}  sm={6} className="nopad" >   <div onClick={()=>this.loadcoin(this.props,asset.asset_id,asset.name,asset.logo,invid,asset.erc)}  className="selectBtn"><img src={asset.logo} className="coinselect"/> {asset.name}</div></Col>


        ))}
        </div>     </div>  
   <div></div>

                   
            </Col>

           

          </Row>
        </Grid>

        </div>

        :stepCount===3
        ?<div className="step3">
              
        <Grid fluid>
          <Row> 
    <div className="coinInfo" id="coinInfo"></div>
                  
          </Row>
        </Grid>

        </div>
        : <div className="step3">
     <div className="buttonHolder">      <Button
            fill
            marginright
            bsStyle="danger"
            type="button"
            onClick={()=>{this.step(2)}}
          >
            Back
          </Button>
          </div><Grid fluid>
        <Row> 
  {address}
  {price}    
  {coin}
        </Row>
      </Grid> </div>
  }

      </div>
    );
  }
}

export default NewTransaction;
