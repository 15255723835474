import React, { Component } from "react";

class Loader extends Component {
    render(){
  return (

    <div className="loadcenter">
    <div className="loaderHolder" >
    <div className="loader center" >
      <i className="fal fa-circle-notch fa-spin"  aria-hidden="true"/>
    </div> </div></div>
  );
}
}
export default Loader;
