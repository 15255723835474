import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Login from "components/Login/Forgot.jsx";
import { userForgot } from 'helpers/user.forgot.jsx';
import logo from "logo.svg";

import bgImage from "assets/img/bg.jpg";
const bgImagecs = { backgroundImage: "url(" + bgImage + ")",backgroundRepeat:"no-repeat",
backgroundSize:"cover" };

class ForgotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardHidden: true,
      username: '',
      usernameError:null,
      submitted: false,
      loading: false,
      error: '',
      passwordreset:'',
      title:<h2>Password Reset</h2>
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}

handleValidate () {

  !this.state.username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
  ? this.setState({
  usernameError: (
    <small className="text-danger">Invalid Email Address</small>
  )
  })
  : this.setState({ usernameError: null });

}

async  handleSubmit(e) {

  await this.handleValidate();
  
  this.handleUpdate() 


  }

  handleUpdate(e) {


    this.setState({ submitted: true });
    const { username,usernameError } = this.state;

    // stop here if form is invalid
    

    if(usernameError === null){
      
 

    this.setState({ loading: true });
    userForgot.forgot(username)
        .then(
            user => {

              this.setState({loading: false,username:'',error:'',title:<h2>Please check your email.</h2>,passwordreset:<div>If an account with {this.state.username} exists you should receive a link to reset your password.<br/><br/>

              If you don’t see an email within 5 minutes, please check your spam folder.<br></br></div>})

          
         
            //  window.location.href ="/#/dashboard";
            },
            error => this.setState({ error, loading: false })
        );
}}

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false }); 
      }.bind(this),
      700
    );
  }
  render() {
    const { username, submitted, loading, error,passwordreset } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
       
              <Login
                hidden={this.state.cardHidden}
                textCenter
                content={  
                  <div>  <div className="logoHolder">
                       <img src={logo} alt="Pay Dev"  className="loginLogo" /></div>
                       <div className="formTop">
            {this.state.title}
          
                  {!passwordreset &&  <p>Enter the username associated with your account and we'll send you a link to reset your password.</p>}
                  </div>
                  
                  {!passwordreset && <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                  <label htmlFor="username">Username/Email</label>
                  <input type="text" className="form-control" name="username" value={username}   onBlur={this.handleValidate}
                            onChange={this.handleChange} />
                      {this.state.usernameError}
                
              </div> }
             
              {!passwordreset &&  <div className="form-group">
                  <button className="btn btn-primary" disabled={loading} onClick={() => this.handleSubmit()}>Continue</button>
                  {loading &&
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
              </div> }
              {passwordreset &&
                  <div >{passwordreset}</div>
              }
              {error &&
                  <div className={'alert alert-danger'}>{error}</div>
              }
  <div className="back" onClick={()=>this.props.history.goBack()}><i class="fal fa-chevron-circle-left"></i> Back</div>
                  </div> 
                
                }
              
                ftTextCenter
              /> 
          
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ForgotPage;
