import React, {Component} from 'react';

import { Grid,  Row,   Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel } from "react-bootstrap";
  import NumberFormat from 'react-number-format';
import { QRCode } from 'react-qrcode-logo';
import Loader from "./loader.jsx";
import Countdown,{zeroPad} from "react-countdown";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactDOM from "react-dom";

const Config = require('config');
const moment = require('moment-timezone');
class Paywidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      expired:false,
      information: '',
      loading: true,
      ref_id:this.props.refid,
      widget_id:this.props.widget_id,
      amount:this.props.amount,
      price:0,
      address:'',
      tag:'',
      coin:this.props.coin,
      erc:this.props.erc,
      expires:'',
      copied: false,
      transactioncount:0,
      wid:'',
      currency:'USD',
      logo:this.props.logo,
      mid:this.props.mid,
      coinname:this.props.coinname,
      coinupper:this.props.coin.toUpperCase(),
      status:'',
      total_payment:0,
      txid:'',
      payment_amount:0,
invid:this.props.invid
    };


  }

checkTransactionsvalID = 0;




componentDidMount(){

  this.getwalletinfo();


}

componentWillUnmount() {

  clearInterval(this.checkTransactionsvalID);
  const node = document.getElementById('coinInfo');

   ReactDOM.unmountComponentAtNode(node)
  }

  cancel=()=>{ 
    
    window.location.href ="/#/dashboard"
      }
    

  displaystatus(){

    if(this.state.status=='Paid'){  clearInterval(this.checkTransactionsvalID)}
   }

  checkTransactions(invid,mid,wid,coin,address,tag){
  
    var user=JSON.parse(localStorage.getItem('user'));
    var token=user.token;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','access-token': token,'mid':mid},
     body: JSON.stringify({invid,mid,wid,coin,address,tag})
  };


  return fetch(Config.API+'/api/pos/check',requestOptions)
    .then( this.handleResponse)
    .then( transactions => this.setState({ status:transactions.data.status,total_payment:transactions.data.total_payment,txid:transactions.data.txid,payment_amount:transactions.data.payment_amount },function(){

this.displaystatus()
    }))


   }




getwalletinfo(){
  var user=JSON.parse(localStorage.getItem('user'));
  var token=user.token;
  var refid=this.props.refid;
  var invid=this.props.invid;
  var  mid=this.props.mid;
  var currency=this.props.currency;
  var widget_id=this.props.widget_id;
  var logo=this.props.logo;
var coin=this.props.coin;
var amount=this.state.amount


  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','access-token': token,'mid':mid},
   body: JSON.stringify({ refid,widget_id,currency,amount,invid})
};


if(this.state.erc==true ){

  return fetch(Config.API+'/api/pos/paynow/erc20/'+coin,requestOptions)
  .then( this.handleResponse)
  .then( walletdata => this.setState({ wid:walletdata.data.wid,tag:walletdata.data.tag,price:walletdata.data.price,address:walletdata.data.address,coin:walletdata.data.coin,currency:walletdata.data.currency,expires:moment.utc(walletdata.data.expires).format(), loading: false},function(){




   // this.checkTransactionsvalID=setInterval(()=>this.checkTransactions(invid,mid,this.state.wid), 10000);
  }));

}else{
return fetch(Config.API+'/api/pos/paynow/'+coin,requestOptions)
  .then( this.handleResponse)
  .then( walletdata => this.setState({ wid:walletdata.data.wid,tag:walletdata.data.tag,price:walletdata.data.price,address:walletdata.data.address,coin:walletdata.data.coin,currency:walletdata.data.currency,expires:moment.utc(walletdata.data.expires).format(), loading: false},function(){




   // this.checkTransactionsvalID=setInterval(()=>this.checkTransactions(invid,mid,this.state.wid), 10000);
  }));

}


}

 handleResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {

       // const error = (data && data.message);
        return 'error'
      }else{
      //  console.log(data.status)
        if(data.status=='error'){
        //  localStorage.removeItem('user');
      //  window.location.reload(true);
             return 'error'
        }


      }


      return data;
  });
}
done=()=>{



  const node = document.getElementById('coinInfo');

   ReactDOM.unmountComponentAtNode(node)

   window.location.href ="/#/dashboard"
  }


checkstatus=()=>{
this.setState({status:'waiting'},function(){
  this.checkTransactionsvalID=setInterval(()=>this.checkTransactions(this.state.ref_id,this.state.mid,this.state.wid,this.state.coin,this.state.address,this.state.tag), 10000);})
}

Back=()=>{

  const node = document.getElementById('coinInfo');

   ReactDOM.unmountComponentAtNode(node)
  clearInterval(this.checkTransactionsvalID)

this.props.parentChildBack()

}

payRemaining=()=>{
  const node = document.getElementById('coinInfo');

   ReactDOM.unmountComponentAtNode(node)
  clearInterval(this.checkTransactionsvalID)
  this.props.parentChildpayRemaining(this.state.amount-this.state.total_payment)
}

  render() {
    const { loading,logo,currency,ref_id,amount,status,total_payment,expires} = this.state;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state

    //return  <span></span>
    return <span className="expired"><div>Sorry this price has expired</div>
    If you haven't sent a transaction, please go back and click on the selected currency again. </span>;
  } else {
      // Render a countdown
      return <span>Price guaranteed for {minutes}:{zeroPad(seconds)}</span>;
    }
  };


    if (loading) return <Loader />
    return (<div >
   <div className="btnHolder">
   {status=='Paid' || status=='Pending'
    ?''
     :<Button
                      fill
                      marginright
                      bsStyle="danger"
                      type="button"
                      onClick={()=>{this.Back()}}
                    >
                      Back
                    </Button>}
    </div>
  <Col sm={12} md={6} lg={6}> <div className="mdFont tcenter vcenter">

                    <br/>
  <div className="section">

  <div>Transaction {this.state.ref_id}</div>
<img src={logo} className="coin"/> 1 {this.state.coin} = {currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'}  <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={this.state.price} />  {this.state.currency}<br/>

{currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'} <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={amount} />  {this.state.currency} = <img src={logo} className="coin"/> <strong >{Number(this.state.amount/this.state.price).toFixed(8)} {this.state.coinupper} </strong>
<div className="marginBot10"></div>
{
  status=='Paid'
?<div>
 { amount==total_payment
   ?<div><div className="mustsend marginBot10 "><strong>Amount Paid {currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'} <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={total_payment} />  {this.state.currency}</strong></div>
  <div className="buttonHolder">  <Button
                      fill
                      marginright
                      bsStyle="info"
                      type="button"
                      onClick={()=>{this.done()}}
                    >
                      Completed
                    </Button></div>
   </div>
   :<div><div className="marginBot10">Amount Paid {currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'} <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={total_payment} />  {this.state.currency} <br/>
   Amount Outstanding {currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'} <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={amount-total_payment} /> {this.state.currency}</div>
   <div className="buttonHolder">  <Button
                      fill
                      marginright
                      bsStyle="warning"
                      type="button"
                      onClick={()=>{this.payRemaining()}}
                    >
                      Pay Remaining
                    </Button>

                    <Button
                      fill
                      marginright
                      bsStyle="danger"
                      type="button"
                      onClick={this.cancel}
                    >
                 Skip Remaining Payment
                    </Button></div>


   </div>
 }</div>
:status=='waiting' || status==''?<div className="mustsend marginBot10"><strong>YOU MUST SEND {Number(this.state.amount/this.state.price).toFixed(8)} {this.state.coinupper}</strong></div>
:<div className="mustsend marginBot10 ">
  <strong>YOU SENT {Number(this.state.payment_amount).toFixed(8)} {this.state.coinupper}</strong><br/>
  <strong>Amount Pending {currency==='USD' || currency==='CAD' ? '$': currency==='EUR' ?'€':'£'} <NumberFormat decimalScale={2}  fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} value={total_payment} />  {this.state.currency}</strong></div>

  }

{ status=='waiting' || status==''
?<div className="marginBot10"><Countdown date={ Date.parse(this.state.expires)}     renderer={renderer}>
</Countdown></div>
:''}
</div>
{ status=='' 
?<div className="disclaimer">For your safety complete your {this.state.coinname} deposit using your personal wallet and not directly from an exchange.</div>
:''}

                    </div> </Col>
          <Col sm={12} md={6} lg={6}>
          <div className="mdFont tcenter vcenter">
        {status==''
        ? <div className="pay-modal_content">
          <div class>
  </div><br/>
      <div className="section">



<br/>
Scan Qr Code With Your {this.state.coinname} Wallet
<br/>
<br/>
<QRCode value={this.state.address} logoImage={logo} size="200" logoOpacity="1" qrStyle="dots" quietZone="10" logoWidth="100" ecLevel="H"/>
</div>
<div className="section">


<div className="address">{this.state.address}</div>
{this.state.coinupper=='XRP'
?<div className="address">Destination Tag: {this.state.tag}</div>
:''
}
<br/>

    </div>





    <div className="section">
    <Button
                      fill
                      marginright
                      bsStyle="success"
                      type="button"
                      onClick={()=>this.checkstatus()}>

                     Payment Sent
                    </Button>



      </div>

    </div>
    : status=='waiting'
    ?<div className="vcenter">       <div className="loaderHolder" > <div className="loader center" >
    <i className="fal fa-circle-notch fa-spin"/>

      </div>    Waiting for Transaction
      </div></div>
    :status=='Pending'
    ?<div className="vcenter">
          <div className="loaderHolder" > <div className="loader center" >
    <i className="fal fa-circle-notch fa-spin"/>
      </div> Transaction Pending
      </div>
     </div>
    : status=='Paid'
    ? <div >
      {amount==total_payment
     ?<div className="vcenter"><div class="w4rAnimated_checkmark">

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
      <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
      <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
    </svg>
    </div>
    Transaction Paid
  </div>
 :<div className="vcenter">
   <div className="partial"><i class="fal fa-exclamation-triangle"></i></div>
   Transaction Partialy Paid</div> }
    </div>
    :<div></div>
        }
     </div>
    </Col>
    </div>

    );
  }
}

Paywidget.defaultProps = {
  hideCloseLink: true,
};

export default Paywidget;