import React, { Component } from "react";
import circle from "circle-notch.svg";
const Config = require('../../config.js');

class Loader extends Component {
    render(){
  return (
    <div className="loaderHolder" >
    <div className="loader center" >
    <i className="fal fa-circle-notch fa-spin"/> 
      </div> </div>
  );
}
}
export default Loader;
