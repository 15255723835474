import React, { Component } from "react";
import { Grid, Col, Row, Container,
  Card, } from "react-bootstrap";
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import moment from 'moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";



const Config = require('config');


class Transactions extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                transactionsdatalist:[],
                from: undefined,
                to: undefined,
                alert: null,
                  
                }
                this.handleFromChange = this.handleFromChange.bind(this);
                this.handleToChange = this.handleToChange.bind(this);
              //  console.log('this.props', this.props)
            }
    
      
          
            handleFromChange(from) {
              // Change the from date and focus the "to" input field
         
              this.setState({ from });
   
            }
          
            handleToChange(to) {
              this.setState({ to }, this.showFromMonth);
            }
            

            
componentWillMount(){

  this.gettransactions()

  }
 
  gettransactions(){

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var widget_id=user.data.widget_id;
    var token=user.token;
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,'mid':mid},
      body:JSON.stringify({widget_id})
   };
      


    fetch(Config.API+'/api/pos/transactions/',requestOptions)
    .then( this.handleResponse)
    .then( transactiondata => this.setState({ transactionsdatalist:transactiondata.data,loading:false}));
   

  }

  back=()=>{ 
    
    window.location.href ="/#/dashboard"
      }
    




  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
          localStorage.removeItem('user');
         window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}




  

  render() {
    const { from, to,alert, } = this.state;
    const modifiers = { start: from, end: to };
    const FORMAT = 'YYYY-MM-DD';
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
       <div className="buttonHolder"> 
         <Button
                      fill
                      marginright
                      bsStyle="danger"
                      type="button"
                      onClick={()=>{this.back()}}
                    >
                      Back
                    </Button>

                    <Button
                      fill
                      pullRight
                      marginleft
                      bsStyle="info"
                      type="button"
                      onClick={()=>{this.setState({loading:true},()=>{this.gettransactions()})}}
                    >
                      <i class="fas fa-sync"></i>
                    </Button></div>
                    
        <Grid fluid>
          <Row>{alert}
  <Col sm={12} >
    

      


      <ReactTable
                  
                  data={this.state.transactionsdatalist}
              
                  columns={[
                    

                    {
                      Header: "Status",
                    Cell: row => ( <div> {row.original.payment_status==='Outstanding' ? 'Pending': row.original.payment_status}</div> )
                    },

                   

                 
                    
                    {
                      Header: "Payment",
                      
                      Cell: row => ( <div> {row.original.currency==='USD' || row.original.currency==='CAD' ? '$':row.original.currency=='EUR'
                      ? '€'
                      :'£'}{row.original.paid_amount} / {row.original.currency==='USD' || row.original.currency==='CAD' ? '$':row.original.currency=='EUR'
                      ? '€'
                      :'£'}{row.original.total_amount} {row.original.currency}</div> )
       
                
                    },
                     
                    {
                      Header: "Date",
                      accessor:"date",
                      filterable: false,
                      Cell: row => {
                        return <Moment format="MM/D/YYYY hh:mm:ss">{row.original.date}</Moment>;
                      },
                    },


                    {
                      Header: "Transaction",
                      accessor:"ref_id"
                     
                 
                    },


                    {
                      Header: "Actions",
                      resizable: false,
                      Cell: row => (
                       <div> <Button
                        fill
                        marginright
                        bsStyle="info"
                        type="button"
                       onClick={() => window.location.hash = "#/transactions/"+row.original.invoice_id}
                      >
                      View
                      </Button>

                     
             
                      
                      </div>),
                     
                 
                    },
                   
                   
                   
                    
                    
                  ]}
                  defaultPageSize={100}
                filterable={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  noDataText= "No Transactions Found"
                  sortable={false}
                  minRows={0}
                  
                />
              



       </Col>
        
           

          </Row>
        </Grid>
      </div>









    );
  }
}

export default Transactions;