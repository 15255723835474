import Dashboard from "views/Dashboard/Dashboard.jsx";
import NewTransaction from "views/New/NewTransaction.jsx";
import TransactionsList from "views/Transactions/TransactionList.jsx";
import TransactionsInfo from "views/Transactions/TransactionInfo.jsx";






var dashboardRoutes = [
 
   {

        path: "/new/:invid",
        name: "New",
        component: NewTransaction,
      signout:false
      },
      {

        path: "/new",
        name: "New",
        component: NewTransaction,
      signout:false
      },
      
       {

        path: "/transactions/:invid",
        name: "transaction",
        component: TransactionsInfo,
        signout:true
      },
  {

        path: "/transactions",
        name: "List",
        component: TransactionsList,
        signout:true
      },
      
     {

        path: "/",
        name: "Home",
        component: Dashboard,
        signout:true
      },
     
      
     


];
export default dashboardRoutes;
