import React, { Component } from "react";
import { Grid,  Row,   Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel } from "react-bootstrap";


import Card from "components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import {

  Nav,

  NavDropdown,
  MenuItem


} from "react-bootstrap";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total:0
    }

  }



  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
 

          <Col sd={12} md={6}> 
  
                       
                   
                        <div className="buttonholder">   <NavLink
                                to='new'
                                className="nav-link"
                                activeClassName="active"
                              
                              >
                        <Button className='newlargebtns'> <span className="btn-label btn-label-right">
                        <i class="fal fa-file-invoice-dollar"></i> <br/>   New Transaction
                             </span></Button> </NavLink></div>
                             </Col>       <Col sd={12} md={6}>   <div className="buttonholder"> <NavLink
                                to='transactions'
                                className="nav-link"
                                activeClassName="active"
                             
                              >
<Button className='listlargebtns'><span className="btn-label btn-label-right">
<i class="fal fa-receipt"></i><br/> Transactions List
                        </span></Button> </NavLink>
</div> </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
