import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";
import PagesHeader from "components/Header/PagesHeader.jsx";

// dinamically create pages routes
import pagesRoutes from "routes/pages.jsx";

import bgImage from "assets/img/bg.jpg";
const bgImagecs = { backgroundImage: "url(" + bgImage + ")",backgroundRepeat:"no-repeat",
backgroundSize:"cover" };
class Pages extends Component {
  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/a/login-page":
        pageClass = " login-page";
        break;
        
      case "/a/register-page":
        pageClass = " register-page";
        break;
        case "/a/forgot-password":
          pageClass = " forgot-password";
          break;
       
      case "/a/lock-screen-page":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }

   if(this.props.match.params.userid!=""){pageClass = " reset-password";}

    return pageClass;
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  render() {
    return (
      <div>
   
        <div className="wrapper wrapper-full-page">
          <div 
            className={"full-page" + this.getPageClass()}
            data-color="black"
            data-image={bgImage}
          >
            <div className="content" style={bgImagecs} >
              <Switch>
                {pagesRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
              </Switch>
            </div>
        
            <div
                   />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
