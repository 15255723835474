import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import MainCard from "components/Card/MainCard.jsx";

import Header from "components/Header/Header.jsx";

// dinamically create dashboard routes
import dashboardRoutes from "routes/dashboard.jsx";

import bgImage from "assets/img/bg.jpg";
const bgImagecs = { backgroundImage: "url(" + bgImage + ")",backgroundRepeat:"no-repeat",
backgroundSize:"cover" };

var ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);
  
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
      madmin:false
    };
  }
  componentDidMount() {
    var user=JSON.parse(localStorage.getItem('user'));
  
    if (user.data.madmin){this.setState({madmin:true})}else{
      this.setState({madmin:false})}
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position,level,message,title) {
  

    // switch (color) {
    //   case 1:
    //     level = "success";
    //     break;
    //   case 2:
    //     level = "warning";
    //     break;
    //   case 3:
    //     level = "error";
    //     break;
    //   case 4:
    //     level = "info";
    //     break;
    //   default:
    //     break;
    // }
    this.state._notificationSystem.addNotification({
      title: title,
      message: (
       message
      ),
      level: level,
      position: position,
      autoDismiss: 3
    });
  }
  render() {
    return (
      <div className="wrapper wrapper-full-page">
      <div 
        className={"full-page"}
        data-color="black"
        data-image={bgImage}
        ref="mainPanel"
      >
           
       <div className="content" style={bgImagecs} >
       <Header {...this.props} />
         
          <Switch>
          {dashboardRoutes.map((prop, key) => {
            if (prop.collapse) {
              return prop.views.map((prop, key) => {
                  
                  return (
                    <Route
                      path={prop.path}

                

                     
                      render={routeProps => (
                     
                     
                     
                        <prop.component
                          {...routeProps}
                          handleClick={this.handleNotificationClick}
                        />
                      )}
                      key={key}
                      
                    />
                    
                  );
                
              });
            } else {
              if (prop.redirect)
                return (
                  <Redirect from={prop.path} to={prop.pathTo} key={key} />
                );
              else
                return (
                  <Route
                    path={prop.path}
            
                       render={routeProps => (
                        <prop.component
                          {...routeProps}
                          handleClick={this.handleNotificationClick}
                        />
                      )}
                    key={key}
                  />
                );
            }
          })}
        </Switch>
    
        </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
